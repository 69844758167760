<template>
  <ItemInfoSheet :table-id="filterInfoSheet">
    <template #info-sheet-title>
      View contact information
    </template>
    <template
      #info-sheet
    >
      <div class="offcanvas-body">
        <div class="mb-1 offcanvas-body--view--title">
          <p class="mb-0">
            Name
          </p>
          <p><strong>{{ user.name }}</strong></p>
        </div>
        <div class="mb-1">
          <div class="">
            <p class="mb-0">
              Status
            </p>
            <div v-html="userStatus" />
          </div>
        </div>
        <hr>
        <div class="mb-1">
          <p>Current position<br>
            <strong>{{ (user.real_professional_background && user.real_professional_background.current_position) ? user.real_professional_background.title : '---' }}</strong></p>
        </div>
        <div class="mb-1">
          <p>University<br>
            <strong>{{ user.real_professional_background ? user.real_professional_background.institution.name : '---' }}</strong></p>
        </div>
        <div class="mb-1">
          <p>Department<br>
            <strong>{{ user.real_professional_background ? user.real_professional_background.department.name : '---' }}</strong></p>
        </div>
        <div class="mb-1">
          <p>Phone<br>
            <strong>{{ user.fix_phone ? user.fix_phone : '---' }}</strong></p>
        </div>
        <div class="mb-1">
          <p>Mobile phone<br>
            <strong>{{ user.phone ? user.phone : '---' }}</strong></p>
        </div>
        <div class="mb-1">
          <p>Email<br>
            <strong>{{ user.personal_email ? user.personal_email : '---' }}</strong></p>
        </div>
      </div>
    </template>
  </ItemInfoSheet>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    ItemInfoSheet,
  },
  props: {
    filterInfoSheet: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
      default: null,
    },
    userStatus: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      user: 'users/user',
    }),
  },
  watch: {
    async userId(newUserId) {
      if (newUserId) {
        await this.$store.dispatch('users/fetchUser', newUserId)
      }
    },
  },
  async mounted() {
    if (this.userId) {
      await this.$store.dispatch('users/fetchUser', this.userId)
    }
  },
}
</script>

<style>

</style>
