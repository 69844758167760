<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Extension requests
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none" />
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              v-if="!loading"
              :sorted-field="sortedField"
              :data="extensionForms"
              :sort-mapping="sortMapping"
              :title="'extension requests'"
              :total="total"
              :fetch-path="'extensionForm/fetchPending'"
              :filter-path="'extensionForm/filterPending'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({name: 'awarded.awardees-list-edit', params: { id: field.academyAwarded }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i data-feather="file" />
                        Justification history
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="false"
                        @click="openFilters(field.academyAwarded.user.id, field.academyAwarded.status.status_table)"
                      >
                        <i data-feather="user" />
                        Contact Information
                      </b-dropdown-item>
                      <template v-if="field.status.status == 'Pendent'">
                        <div class="dropdown-divider" />
                        <b-dropdown-item @click="save(field.id, 1)">
                          <i
                            class="me-50"
                            data-feather="thumbs-up"
                          /> Accept extension
                        </b-dropdown-item>
                        <b-dropdown-item @click="save(field.id, 2)">
                          <i
                            class="me-50"
                            data-feather="thumbs-down"
                          /> Denied extension
                        </b-dropdown-item>
                      </template>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Name</label>
                  <input
                    v-model="filters.name"
                    type="text"
                    class="form-control"
                    name="name"
                    @change="saveFilter({ customLabel: true, name: `Name: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Call</label>
                  <v-select
                    v-model="filters.calls"
                    multiple
                    label="title"
                    :options="convos"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Calls: ${$event ? ($event.map(e => e.title)).join(' - ') : ''}`, value: $event }, 'calls')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Status extension</label>
                  <v-select
                    v-model="filters.status"
                    label="name"
                    :options="statuses"
                    :get-option-key="option => option.name"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.name : ''}`, value: $event }, 'status')"
                  />
                </div>
              </template>
            </CompleteTable>
            <div
              v-if="loading"
              id="loading-bg"
            >
              <div
                class="loading"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ContactInformation
      :filter-info-sheet="filterInfoSheet"
      :user-id="userId"
      :user-status="userStatus"
    /></div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../../partials/components/CompleteTable.vue'
import ContactInformation from '../components/ContactInformation.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ContactInformation,
  },
  data() {
    return {
      sortedField: '',
      sortMapping: {
        Name: 'user.name',
        Call: 'convo.code',
        Area: 'area',
        Extension: 'extensionLabel',
        Reason: 'reason',
        Status: 'status.status_table',
      },
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Call',
          checked: true,
          order: 2,
        },
        {
          name: 'Area',
          checked: true,
          order: 3,
        },
        {
          name: 'Extension',
          checked: true,
          order: 4,
        },
        {
          name: 'Reason',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
      ],
      userId: null,
      userStatus: null,
      filterInfoSheet: 2,
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      extensionForms: 'extensionForm/items',
      total: 'extensionForm/itemsTotal',
      loggedUser: 'auth/admin',
      filters: 'filters/filters',
      convos: 'convo/convos',
      statuses: 'status/statuses',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'university-extension-form')
    if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'university-extension-form',
      })
    }
    await this.$store.dispatch('extensionForm/fetchPending')
    this.loading = false

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)

    await this.$store.dispatch('status/filterStatus', 'Extension requests')
    await this.$store.dispatch('convo/fetchAll', 'academy')

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
  methods: {
    async onSearch(search, store) {
      if (search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
    saveFilter(value, field) {
      if (field === 'calls') {
        value.value.map(e => {
          e.name = e.title
          return e
        })
      }

      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async openFilters(userId, status) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.userStatus = status
      this.userId = userId
    },
    async save(id, status) {
      this.loading = true
      try {
        await this.$store.dispatch('extensionForm/update', { id, status })
        Vue.swal('Extension request updated successfully!', '', 'success')
        await this.$store.dispatch('extensionForm/fetchPending')
      } catch (e) {
        console.log(e)
      }

      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.loading = false
    },
  },
}
</script>

<style>

</style>
